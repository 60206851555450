.author {
    @apply flex flex-col text-center md:flex-row md:items-center md:text-left mb-12 mt-12 text-xl leading-10 md:leading-7;
}
.cover-image {
    margin-left: -20px;
    margin-right: -20px;
    @apply mb-8 md:mb-16 /*-mx-5*/ sm:mx-0;
    /*width: 100%;*/
    /*position: relative;*/
    /*
    left: 0;
    right: 0;
    margin: 0;
    */
}

.cover-image figcaption {
    text-align: center;
    font-style: italic;
    padding-top: 5px;
    /*
    position: absolute;
    bottom: 0;
    left: 0;
    background: var(--clrDarkShades);
    color: #fff;
    padding: 5px 10px;
    color: var(--clrLightShades);
    */
}

.cover-image figcaption a {
    text-decoration: underline;
}