.list {
    @apply md:flex md:flex-1 justify-center flex-wrap md:justify-start;
}
@screen md {
    .list li {
        white-space: pre;
    }
    .list li:after {
        content: " / ";
    }
    .list li:last-child:after {
        content: "";
    }
}